<template>
  <v-card-text class="text-center" :style="{ width: width }">
    <div class="w-100 d-flex justify-center pb-4">
      <v-avatar :size="avatarSize" :color="avatarColour">
        <v-icon :icon="icon" :color="iconColour" :size="iconSize" />
      </v-avatar>
    </div>
    <div class="text-subtitle-1" style="font-weight: 600">
      <slot name="title" />
    </div>
    <div class="text-caption">
      <slot name="body" />
    </div>
    <div>
      <slot name="footer" />
    </div>
  </v-card-text>
</template>
<script>
export default {
  props: {
    avatarSize: {
      type: String,
      default: "100",
    },
    avatarColour: {
      type: String,
      default: "#BFE0F8",
    },
    icon: {
      type: String,
    },
    iconSize: {
      type: String,
      default: "54",
    },
    iconColour: {
      type: String,
      default: "#093F6B",
    },
    width: {
      type: String,
      default: "340px",
    },
  },
};
</script>
