<template>
  <v-card-text class="text-left pa-0">
    <!-- Search box -->
    <v-card-actions class="pa-0 my-4">
      <v-text-field
        v-model="filters.searchString"
        variant="outlined"
        density="compact"
        rounded="0"
        hide-details
        style="background-color: white"
        label="Enter Indicator Name"
        @keydown.enter="getIndicators"
        autocomplete="off"
      ></v-text-field>
      <v-btn color="primary" tile variant="elevated" @click="getIndicators">
        search
      </v-btn>
    </v-card-actions>
    <v-card class="my-2" elevation="0" style="background-color: #f9f9f9">
      <!-- Tags -->
      <DatastoreCatalogueTags
        v-model:tagsProp="filters.tags"
        @search="getIndicators"
      />

      <v-card-text class="py-0 px-2">
        <!-- Filters -->
        <v-row
          v-if="indicators.length || fetchingIndicators"
          class="px-0 my-2 align-center"
        >
          <v-col cols="9" class="text-h6 text pb-0">
            {{
              fetchingIndicators
                ? "Searching..."
                : `We found ${indicators.length} results that match your query`
            }}
          </v-col>
          <v-col cols="3 pb-0">
            <v-select
              v-model="filters.sortBy"
              :items="[
                { title: 'Alphabetically (Asc)', value: 'asc' },
                { title: 'Descending (Desc)', value: 'desc' },
              ]"
              no-data-text="No filters available"
              hide-details
              rounded="0"
              style="background-color: white"
              @update:modelValue="getIndicators()"
              label="Sort"
              variant="outlined"
              density="compact"
            />
          </v-col>
        </v-row>

        <!-- Suggested prompts -->
        <v-card-text
          v-if="!indicators.length && !noResults && !fetchingIndicators"
          class="pa-0"
        >
          <div class="text-h6 text pb-2">
            Not sure where to start? Try one of these:
          </div>
          <v-card-text class="promptCard pa-0">
            <v-card-actions
              v-for="(prompt, i) in suggestedPrompts"
              :key="i"
              class="prompt px-4 mb-6"
              @click.prevent="runPrompt(prompt)"
            >
              <v-icon icon="mdi-magnify" />
              <div style="font-weight: 600; font-size: 16px">{{ prompt }}</div>

              <v-spacer />
              <v-icon icon="mdi-chevron-right" />
            </v-card-actions>
          </v-card-text>
        </v-card-text>

        <!-- Fetched indicators -->
        <v-card-text
          v-if="indicators.length && !fetchingIndicators"
          class="pa-0"
        >
          <IndicatorCard
            v-for="indicator in indicators"
            :key="indicator.id"
            :indicator="indicator"
          >
            <template #firstColumn>
              <!-- API URL -->
              <v-card-actions class="px-0 py-2">
                <v-icon icon="mdi-code-tags" />
                <v-card-text class="pa-0">
                  <div class="text-subtitle-1">API URL</div>
                  <div
                    @click.stop="copyApiURL(indicator)"
                    style="color: #1867c0; cursor: pointer; width: fit-content"
                  >
                    {{ indicator.source }}
                    <v-icon
                      :icon="
                        indicator.isUrlCopied ? 'mdi-check' : 'mdi-content-copy'
                      "
                      class="ml-1"
                      color="primary"
                      size="small"
                    />
                  </div>
                </v-card-text>
              </v-card-actions>

              <!-- Extra buttons -->
              <div class="d-flex pt-2">
                <v-btn
                  prepend-icon="mdi-eye-outline"
                  variant="outlined"
                  rounded="0"
                  >watch</v-btn
                >
                <v-spacer />
                <v-btn
                  color="success"
                  variant="elevated"
                  rounded="0"
                  @click="addToQuery(indicator)"
                  class="mr-2"
                >
                  build query
                </v-btn>
              </div>
            </template>
          </IndicatorCard>
        </v-card-text>

        <!-- Skeleton laoders for indicator cards. We can see them while searching and then we chuck them underneath for lazy loading -->
        <v-card-text
          v-if="
            fetchingIndicators ||
            (indicators.length && !areAllIndicatorsFetched)
          "
          class="pa-0 padinatedSkeletons"
        >
          <IndicatorCard v-for="n in 5" :key="n" :loading="true">
            <!-- Extra buttons loading -->
            <template #firstColumn>
              <div class="d-flex pt-2">
                <v-btn
                  disabled
                  prepend-icon="mdi-eye-outline"
                  variant="outlined"
                  rounded="0"
                  >watch</v-btn
                >
                <v-spacer />
                <v-btn
                  disabled
                  color="success"
                  prepend-icon="mdi-plus"
                  variant="elevated"
                  rounded="0"
                  class="mr-2"
                >
                  add to query
                </v-btn>
              </div>
            </template>
          </IndicatorCard>
        </v-card-text>

        <!-- No results found -->
        <v-card-text v-if="noResults" class="pa-0 pt-2">
          <v-alert
            color="warning"
            icon="mdi-information"
            size="10px"
            prominent
            class="datastoreNoResults"
          >
            <div style="font-weight: 500">No search results found</div>
            <div>
              We were unable to find any search results for your query, please
              try again with a different parameters
            </div>
          </v-alert>
        </v-card-text>
      </v-card-text>
    </v-card>

    <!-- dialog - replace indicator -->
    <DynamicDialog
      :show="dialogIndicatorQueryOverwriting"
      @dialogOk="addToQuery(clickedIndicator, true)"
      @dialogCancel="dialogIndicatorQueryOverwriting = false"
      @update:showDyamicDialog="
        (state) => (dialogIndicatorQueryOverwriting = state)
      "
      max-width="550"
      okBtnColor="success"
      cancelBtnColor="none"
      cancelBtnVariant="outlined"
    >
      <template v-slot:title>Replace Current Indicator?</template>
      <template v-slot:content>
        <p>
          Adding this new indicator will replace the one currently in your
          query. Any customisations or settings applied to the existing
          indicator will be lost.
        </p>
      </template>
      <template v-slot:okBtnTitle>Replace Indicator</template>
    </DynamicDialog>
  </v-card-text>
</template>
<script>
import IndicatorCard from "./IndicatorCard.vue";
import DynamicDialog from "@/components/DynamicDialog.vue";
import DatastoreCatalogueTags from "@/components/DatastoreCatalogueTags.vue";
import { copyToClipboard } from "../mixins/CopyToClipboard";
import { systemMessages } from "@/mixins/SystemMessages";
import { useDisplay } from "vuetify";

export default {
  name: "DatastoreCatalogue",
  components: {
    IndicatorCard,
    DynamicDialog,
    DatastoreCatalogueTags,
  },
  mixins: [systemMessages],
  data: () => ({
    suggestedPrompts: [
      "Index of multiple deprivation",
      "All indicators in the England ",
      "All indicators in the England last published after 1 Jan 2024",
      "Indicators where the source contains the text “Department”,  Last published after 1 Jan 2024",
      "Index of multiple deprivation 2023",
      "Indicators where the source contains the text “Department”",
    ],
    pageHeight: useDisplay().height,
    indicators: [],
    filters: {
      searchString: "",
      tags: [],
      sortBy: "asc",
    },
    fetchingIndicators: false,
    areAllIndicatorsFetched: false,
    noResults: false,
    paginatedFetch: false,
    dialogIndicatorQueryOverwriting: false,
    clickedIndicator: null,
  }),
  computed: {
    selectedIndicator: {
      get() {
        return this.$store.getters["datastore/selectedIndicator"];
      },
      set(val) {
        this.$store.commit("datastore/setSelectedIndicator", val);
      },
    },
  },
  mounted() {
    this.filters.searchString = this.$route.query?.s || "";
    this.filters.sortBy = this.$route.query?.sort || "asc";
    this.filters.tags = JSON.parse(this.$route.query?.tags || "[]");

    // if anything is coming through the URL - run search
    if (this.filters.searchString || this.filters.tags?.length) {
      this.getIndicators();
    }
  },
  methods: {
    async getIndicators() {
      this.noResults = false;
      this.indicators = [];

      if (this.filters["searchString"] || this.filters.tags?.length) {
        const limit = this.getAvailableSlotsForIndicators();
        this.filters.limit = limit;

        try {
          this.fetchingIndicators = true;
          const response = await this.$axios.post(
            "/datastore/catalogue",
            this.filters,
          );

          this.indicators = response.data.indicators;
          this.areAllIndicatorsFetched = response.data.areAllIndicatorsFetched;

          this.noResults = !this.indicators?.length;
        } catch (error) {
          console.error(error);
        } finally {
          this.fetchingIndicators = false;
        }
      }
    },
    async getPaginatedIndicators() {
      if (this.areAllIndicatorsFetched || this.paginatedFetch) return;

      this.paginatedFetch = true;
      const limit = this.getAvailableSlotsForIndicators();
      this.filters.limit = limit;
      this.filters.fetchedIndicatorIds = this.indicators.map((i) => i.id); // for pagination
      try {
        const response = await this.$axios.post(
          "datastore/catalogue/pagination",
          this.filters,
        );

        this.indicators.push(...response.data.indicators);
        this.areAllIndicatorsFetched = response.data.areAllIndicatorsFetched;
      } catch (error) {
        console.error(error);
      } finally {
        this.paginatedFetch = false;
      }
    },
    runPrompt(prompt) {
      this.filters.searchString = prompt;

      this.getIndicators();
    },
    // Fetch indicators based on the user's display height
    getAvailableSlotsForIndicators() {
      const INDICATOR_CARD = 94;
      const freeSpace = this.pageHeight - 400;

      return Math.floor(freeSpace / INDICATOR_CARD); // plus extra
    },
    copyApiURL(indicator) {
      copyToClipboard(indicator.api_url);

      this.indicators.find((i) => i.id === indicator.id).isUrlCopied = true;

      this.successPill({
        icon: "mdi-content-copy",
        title: "Copied to clipboard",
      });
      setTimeout(() => {
        this.indicators.find((i) => i.id === indicator.id).isUrlCopied = false;
      }, 3000);
    },
    lazyLoading(event) {
      if (
        event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight - 454
      ) {
        this.getPaginatedIndicators();
      }
    },
    addToQuery(indicator, force = false) {
      if (force) {
        this.selectedIndicator = indicator;
        this.dialogIndicatorQueryOverwriting = false;
        this.$router.push("/datastore/query-builder");
        return;
      }

      this.clickedIndicator = indicator;

      if (this.selectedIndicator) {
        this.dialogIndicatorQueryOverwriting = true;
      } else {
        this.selectedIndicator = JSON.parse(JSON.stringify(indicator));
        this.$router.push("/datastore/query-builder");
      }
    },
  },
  watch: {
    filters: {
      handler(val) {
        this.$router.push({
          path: "/datastore/catalogue",
          query: {
            s: val.searchString,
            sort: val.sortBy,
            tags: JSON.stringify(val.tags),
          },
        });
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.prompt {
  border: solid 1px #c2c2c2;
  border-radius: 8px;
  color: #0e5b99;
  background: white;
}

.promptCard :hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
</style>
<style>
/* remove box shadow from chips */
.chips .v-chip--variant-elevated {
  box-shadow: none;
}
</style>
