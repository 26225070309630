<template>
  <v-container fluid pa-5 fill-height>
    <v-progress-circular
      v-if="loadingMetaData"
      style="
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translateY(-50%, -50%);
      "
      :size="180"
      :width="2"
      :color="this.$store.state.config.siteConfig.toolbar_colour"
      indeterminate
    >
      Loading...
    </v-progress-circular>
    <v-dialog v-model="importingDataDialog" width="550" persistent>
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
        >
          <v-btn
            icon="mdi-close"
            @click="importingDataDialog = false"
            label="Close"
            title="Close"
          ></v-btn>
          <v-toolbar-title class="text-left"
            >Importing Your Data...</v-toolbar-title
          ></v-toolbar
        >
        <v-card-actions>
          <v-card-text class="text-center text-body-1">
            Importing {{ this.rowCount }} rows of data, this could take a few
            minutes. <br />
            Please keep this page open until the import is complete.
            <br />
            <br />
            Elapsed Time:
            {{ this.hrString + ":" + this.minString + ":" + this.secString }}
            <br />
            <br />
            <v-progress-linear indeterminate color="green"></v-progress-linear>
            <br />
          </v-card-text>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDeleteDialog" width="550">
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
        >
          <v-toolbar-title class="text-center"
            >Confirm Custom Data Deletion?</v-toolbar-title
          ></v-toolbar
        >
        <v-card-actions>
          <v-card-text class="text-center text-body-1">
            Are you sure you want to delete this custom data? You
            <i>cannot</i> undo this action!
          </v-card-text>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            variant="elevated"
            tile
            @click="confirmDeleteDialog = false"
            :disabled="deleting"
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            variant="elevated"
            tile
            @click="deleteData()"
            :loading="deleting"
            aria-label="Delete"
          >
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="importOptionsDialog"
      width="550"
      @click:outside="abort(true)"
      persistent
    >
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
        >
          <v-btn
            icon="mdi-close"
            @click="abort(true)"
            label="Close"
            title="Close"
          ></v-btn>
          <v-toolbar-title class="text-left"
            >Import Options</v-toolbar-title
          ></v-toolbar
        >
        <v-card-actions v-if="importOptionsMessage">
          <v-card-text class="text-body-1">
            <div class="text-subtitle-1 mb-3">You have two options:</div>
            <ul class="ml-5">
              <li class="mb-2">
                Overwrite the current data related to this Indicator?
              </li>
              <li class="mb-2">
                Add to the current data related to this Indicator but with a new
                date?
              </li>
            </ul>
            <v-row cols="12">
              <v-col cols="6" class="text-left mt-5"
                ><v-btn
                  color="error"
                  variant="elevated"
                  tile
                  @click="overWriteButton(true)"
                  aria-label="Overwrite"
                >
                  Overwrite
                </v-btn></v-col
              >
              <v-col cols="6" class="text-right mt-5"
                ><v-btn
                  color="success"
                  tile
                  variant="elevated"
                  @click="addMoreDataButton(true)"
                  aria-label="Add More Data"
                >
                  Add More Data
                </v-btn></v-col
              >
            </v-row>
          </v-card-text>
        </v-card-actions>
        <v-card-actions v-if="confirmOverwrite">
          <v-card-text class="text-center">
            <div class="text-subtitle-1 mb-3">Confirm Overwrite Data?</div>
            <v-row cols="12">
              <v-col cols="6" class="text-left mt-5"
                ><v-btn
                  color="info"
                  variant="elevated"
                  @click="overWriteButton(false)"
                  aria-label="Back"
                >
                  Back
                </v-btn></v-col
              >
              <v-col cols="6" class="text-right mt-5"
                ><v-btn
                  color="error"
                  variant="elevated"
                  @click="overWriteDataConfirmed()"
                  aria-label="Overwrite"
                >
                  Overwrite
                </v-btn></v-col
              >
            </v-row>
          </v-card-text>
        </v-card-actions>
        <v-card-actions v-if="addToCurrentDataSelectDate">
          <v-card-text class="text-center">
            <Field
              :schema="fieldSchema['date']"
              v-model:value="selectedMetaDataObject['date'].value"
            />
            <v-row cols="12">
              <v-col cols="6" class="text-left mt-5"
                ><v-btn
                  color="info"
                  variant="elevated"
                  tile
                  @click="addMoreDataButton(false)"
                  aria-label="Back"
                >
                  Back
                </v-btn></v-col
              >
              <v-col cols="6" class="text-right mt-5"
                ><v-btn
                  :disabled="this.differentDateError === '' ? false : true"
                  color="success"
                  variant="elevated"
                  tile
                  @click="addMoreDataNext()"
                  aria-label="Next"
                >
                  Next
                </v-btn></v-col
              >
            </v-row>
          </v-card-text>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <!-- the parent card/tile -->
        <v-card tile min-height="100%" variant="text">
          <v-card-title><h1 class="h1 text-left">Custom Data</h1></v-card-title>
          <v-card-actions>
            <v-divider id="divide" />
          </v-card-actions>
          <v-card-actions>
            <v-row cols="12">
              <v-col cols="12" md="6" sm="12">
                <v-select
                  v-model="selectedMetaDataItem"
                  clearable
                  label="Import or Edit Custom Data"
                  :items="allMetaData"
                  variant="outlined"
                  density="compact"
                  rounded="0"
                  :loading="busyMetaData"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="text-left">
                <v-btn
                  v-if="selectedMetaDataObject"
                  color="success"
                  tile
                  variant="elevated"
                  @click="save()"
                  width="200"
                  :loading="busysaving"
                  aria-label="Save"
                >
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon light>mdi-cached</v-icon>
                    </span>
                  </template>
                  Save
                </v-btn></v-col
              >
            </v-row>
          </v-card-actions>
          <v-card-text
            v-if="selectedMetaDataObject && !loadingMetaData"
            :style="'max-height:' + height + 'px; overflow-y: auto'"
          >
            <v-form ref="form" v-model="validation" lazy-validation>
              <v-container class="ma-0 pa-0">
                <v-row cols="12">
                  <v-col cols="12" sm="12" md="6">
                    <h3 class="text-h5 mb-3 text-left">
                      {{
                        selectedMetaDataItem === 0
                          ? "New Custom Data"
                          : selectedMetaDataObject.indicator_name.value
                      }}
                    </h3>
                    <v-alert
                      variant="tonal"
                      type="info"
                      title="Custom data file format"
                      class="text-left text-body-1"
                    >
                      <div class="mb-3">
                        To import data it must be in a specific format with set
                        headers. Download the data template below to see how it
                        should be formatted.
                      </div>
                      <v-btn
                        class="mb-5"
                        color="info"
                        tile
                        @click="downloadTemplate()"
                        title="click to download a data template form"
                        aria-label="Data Template"
                      >
                        Data Template
                      </v-btn>
                      <div class="mb-3">
                        Column A is for area codes and cell A1 must have the
                        header “area_code”
                      </div>
                      <div class="mb-3">
                        Column B is for your data and cell B1 must have the
                        header “value”
                      </div>
                    </v-alert>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-file-input
                      id="fileImport"
                      v-model="importFile"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      clearable
                      variant="outlined"
                      density="compact"
                      rounded="0"
                      label="Select Data File"
                      class="fields mt-11"
                      :error-messages="fileError"
                      @change="inputFileFieldOnChange"
                      @mousedown:control="fileInputFocus"
                      @keydown.enter="fileInputFocus"
                      @blur="fileBlur"
                      @click:clear="abort(true)"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row cols="12">
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    v-for="(item, key) in displayedFieldSchema"
                    :key="key"
                    style="padding-top: 0px !important"
                  >
                    <Field
                      :fieldID="key"
                      :schema="displayedFieldSchema[key]"
                      v-model:value="selectedMetaDataObject[key].value"
                      v-on:click="dateCoverageTextOptions"
                      v-on:change="dateCoverageTextOptions"
                      v-on:keyup="dateCoverageTextOptions"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    style="padding-top: 0px !important"
                  >
                    <v-row>
                      <v-col>
                        <v-checkbox
                          hide-details
                          class="left-align-label"
                          v-model="restrictAggOnMap"
                          aria-label="Restrict aggregation on the map"
                        >
                          <template v-slot:label>
                            <div>
                              <div>Restrict aggregation on the map</div>
                              <div class="subheader">
                                Enable this option to restrict the aggregation
                                of this data, ensuring that spatial data
                                calculations are limited to specific area types
                              </div>
                            </div>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                    <v-progress-circular
                      v-if="loadingAggLevels"
                      indeterminate
                      color="primary"
                      class="my-3"
                    ></v-progress-circular>

                    <v-row v-else-if="restrictAggOnMap" class="ml-0 mr-0">
                      <div class="pa-2">
                        Select area types for data exploration
                      </div>
                      <v-col
                        class="agglevels"
                        cols="12"
                        v-for="aggLevel in aggLevels"
                        :key="aggLevel.id"
                      >
                        <v-card
                          class="ml-0 mr-0 mb-0"
                          variant="outlined"
                          density="compact"
                          rounded="0"
                          style="
                            border-color: lightgrey;
                            border-width: 1px;
                            margin-top: -1px;
                          "
                        >
                          <v-checkbox
                            hide-details
                            v-model="selectedAggLevels"
                            :label="`${aggLevel.area_level_name} (${aggLevel.area_level_name_abr})`"
                            :value="aggLevel.id"
                          ></v-checkbox>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-row cols="12" class="mt-4">
              <v-col lg="4" sm="12" md="6" class="text-left">
                <v-btn
                  :disabled="!selectedMetaDataItem"
                  color="error"
                  tile
                  @click="confirmDeleteDialog = true"
                  aria-label="Delete This Data"
                >
                  Delete This Data
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomDataSchema from "@/schemas/CustomDataSchema";
import Field from "@/components/Fields.vue";
import { utils, writeFile, read } from "xlsx";

export default {
  name: "CustomData",
  data: () => ({
    hour: 0,
    minute: 0,
    second: 0,
    timer: false,
    count: 0,
    hrString: 0,
    minString: 0,
    secString: 0,
    countString: 0,
    importingDataDialog: false,
    busysaving: false,
    busy: false,
    busyMetaData: false,
    loadingMetaData: false,
    loadingAggLevels: false,
    saveMetaDataObject: null,
    originalDate: "",
    originalDateCoverageText: "",
    addMoreData: false,
    overWriteData: false,
    confirmOverwrite: false,
    importOptionsMessage: false,
    addToCurrentDataSelectDate: false,
    confirmDeleteDialog: false,
    importOptionsDialog: false,
    importFile: null,
    fileError: null,
    fieldSchema: CustomDataSchema,
    selectedMetaDataItem: null, // id of the selected metadata object
    selectedMetaDataObject: null,
    allMetaData: [],
    metaDataTemplate: {},
    validation: false,
    deleting: false,
    data: null,
    rowCount: 0,
    dataCount: 0,
    startTime: null,
    aggLevels: [],
    restrictAggOnMap: false,
    selectedAggLevels: [],
  }),
  components: {
    Field,
  },
  computed: {
    displayedFieldSchema() {
      return Object.entries(this.fieldSchema)
        .filter(
          ([, item]) => item.useForTemplate === true && item.show === true,
        )
        .reduce((acc, [key, item]) => {
          acc[key] = item;
          return acc;
        }, {});
    },
    height() {
      // switch (this.$vuetify.breakpoint.name) {
      // default:
      return (
        window.innerHeight -
        document.querySelector("#divide").getBoundingClientRect().bottom -
        115
      );
      // }
    },
    differentDateError: {
      get() {
        if (this.addToCurrentDataSelectDate || this.addMoreData) {
          return !this.selectedMetaDataObject.date.value ||
            this.selectedMetaDataObject.date.value !== this.originalDate
            ? ""
            : "cannot be the same date";
        } else {
          return null;
        }
      },
    },
    disableDateCoverageText: {
      get() {
        if (
          this.selectedMetaDataObject.date_coverage_number.value &&
          this.selectedMetaDataObject.date_coverage_units.value &&
          this.selectedMetaDataObject.date.value
        ) {
          return false;
        } else {
          return true;
        }
      },
    },
  },
  props: {
    model: null,
    item: {},
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
    relatedModelResults: {},
  },
  mounted() {
    this.getAllMetadata();
    this.setImportLevelValues();
  },
  methods: {
    setImportLevelValues() {
      this.fieldSchema.import_level.selectItems = [];
      let abr;
      for (
        var i = 0;
        i < this.$store.state.config.siteConfig.area_level_names.length;
        i++
      ) {
        abr =
          this.$store.state.config.siteConfig.area_level_names[i]
            .area_level_name_abr;
        if (abr.toUpperCase() !== "WD" && abr.toUpperCase() !== "DISTRICT") {
          this.fieldSchema.import_level.selectItems.push({
            text: abr.toUpperCase(),
            value: abr.toLowerCase(),
          });
        }

        // if it's a Scottish district, set it to our recognised 'ca' (Council Area)
        if (abr.toUpperCase() == "DISTRICT") {
          this.fieldSchema.import_level.selectItems.push({
            text: abr.toUpperCase(),
            value: "ca",
          });
        }
      }
    },
    startClock() {
      clearTimeout(this.timer);
      this.hour = "0";
      this.minute = "0";
      this.second = "0";
      this.startTime = Date.now();
      this.timer = setInterval(() => {
        this.clock();
      }, 100);
    },
    clock() {
      var elapsedTime = Date.now() - this.startTime;

      this.second = parseInt((elapsedTime / 1000) % 60);
      this.minute = parseInt((elapsedTime / (1000 * 60)) % 60);
      this.hour = parseInt((elapsedTime / (1000 * 60 * 60)) % 24);

      this.hrString = this.hour;
      this.minString = this.minute;
      this.secString = this.second;
      this.countString = this.count;

      if (this.hour < 10) {
        this.hrString = "0" + this.hrString;
      }

      if (this.minute < 10) {
        this.minString = "0" + this.minString;
      }

      if (this.second < 10) {
        this.secString = "0" + this.secString;
      }
    },
    downloadTemplate() {
      const worksheet = utils.json_to_sheet([
        { area_code: null },
        { value: null },
      ]);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet);
      writeFile(workbook, "Your Data Template.xlsx", {
        compression: true,
      });
    },
    importNewData(metaDataResponse) {
      this.data = {};
      this.rowCount = 0;
      var reader = new FileReader();
      reader.readAsBinaryString(this.importFile);
      reader.onload = () => {
        try {
          var data = this.removeCSVEmptyColumns(reader.result);
        } catch (error) {
          this.emit.emit("systemMessage", {
            title: `Error! Failed to Import Data.`,
            message: `${error}`,
            timeout: -1,
            colour: "error",
          });
          this.afterSaveCleanup(metaDataResponse);
          return;
        }
        var fileContents = read(data, { type: "binary" });
        var sheetName = fileContents["SheetNames"][0];
        fileContents.Sheets[sheetName];
        // check the first column is the area code (add some clean-up incase the user has added a few specials)
        fileContents.Sheets[sheetName]["A1"].v = fileContents.Sheets[sheetName][
          "A1"
        ].v
          .toLowerCase()
          .trim();
        fileContents.Sheets[sheetName]["A1"].v = fileContents.Sheets[sheetName][
          "A1"
        ].v.replace("areacode", "area_code");
        fileContents.Sheets[sheetName]["A1"].v = fileContents.Sheets[sheetName][
          "A1"
        ].v.replace("area code", "area_code");
        if (fileContents.Sheets[sheetName]["A1"].v !== "area_code") {
          console.error("invalid area_code column");
          this.emit.emit("systemMessage", {
            title: "Error! Failed to Import Data.",
            message:
              'No "area_code" column header detected in the import files column "A". Please fix or download and use the Data template file instead.',
            timeout: -1,
            colour: "error",
          });
          this.afterSaveCleanup(metaDataResponse);
          return;
        }
        // check the second column are the values (add some clean-up incase the user has added a few specials)
        fileContents.Sheets[sheetName]["B1"].v = fileContents.Sheets[sheetName][
          "B1"
        ].v
          .toLowerCase()
          .trim();
        if (fileContents.Sheets[sheetName]["B1"].v !== "value") {
          console.error("invalid value column");
          this.emit.emit("systemMessage", {
            title: "Error! Failed to Import Data.",
            message:
              'No "value" column header detected in the import files column "B". Please fix or download and use the Data template file instead.',
            timeout: -1,
            colour: "error",
          });
          this.afterSaveCleanup(metaDataResponse);
          return;
        }
        // now add the data
        this.data[
          metaDataResponse.data.indicator_code +
            "." +
            metaDataResponse.data.date.replaceAll("-", "")
        ] = utils.sheet_to_json(fileContents.Sheets[sheetName], {
          defval: "",
        });

        // check there is some data
        this.rowCount =
          this.data[
            metaDataResponse.data.indicator_code +
              "." +
              metaDataResponse.data.date.replaceAll("-", "")
          ].length;

        if (this.rowCount < 2) {
          console.error("No Data In Import File");
          this.emit.emit("systemMessage", {
            title: "Error! Failed to Import Data.",
            message: "No Data In Import File, it appears to be empty :/",
            timeout: -1,
            colour: "error",
          });
          this.afterSaveCleanup(metaDataResponse);
          return;
        }

        // add the overwrite flag
        this.data["overWriteData"] = this.overWriteData;
        this.importingDataDialog = true;
        this.startClock();
        // make the call!
        this.$axios
          .post("/custom-data", this.data)
          .then((response) => {
            this.data[
              metaDataResponse.data.indicator_code +
                "." +
                metaDataResponse.data.date.replaceAll("-", "")
            ] = null;
            this.afterSaveCleanup(metaDataResponse);
            if (response.data.success) {
              this.emit.emit("systemMessage", {
                title: "Success!",
                message: "Data Import Complete",
                timeout: -1,
                colour: "success",
              });
            } else {
              this.emit.emit("systemMessage", {
                title: "Failed to Import Data",
                message: response.data.message,
                timeout: -1,
                colour: "error",
              });
            }
          })
          .catch((error) => {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title: "Failed to Import Data",
              message: error,
              timeout: -1,
              colour: "error",
            });
            this.data[
              metaDataResponse.data.indicator_code +
                "." +
                metaDataResponse.data.date.replaceAll("-", "")
            ] = null;
            this.afterSaveCleanup(metaDataResponse);
          });
      };
    },
    /** removes empty columns at the end of a csv string and checks for empty column values in each row */
    removeCSVEmptyColumns(data) {
      // Normalize line endings to handle Windows (\r\n) and Linux (\n)
      data = data.replace(/\r\n/g, "\n").replace(/\r/g, "\n");

      // Parse CSV data into array of arrays of row data
      let rows = data.split("\n").map((row) => row.split(","));

      // Remove empty columns at end of each row and filter out empty rows
      let filteredData = rows
        .map((row) => {
          let lastNonEmptyIndex = row.length - 1;
          while (
            lastNonEmptyIndex >= 0 &&
            row[lastNonEmptyIndex].trim() === ""
          ) {
            lastNonEmptyIndex--;
          }
          // Slice the row up to the last non-empty index + 1
          return row.slice(0, lastNonEmptyIndex + 1);
        })
        .filter((row) => row.length > 0); // Filter out empty rows

      // Check whether data contains missing values for each column and throw an error
      let columnCount = Math.max(...filteredData.map((row) => row.length));
      // Loop data rows
      let emptyRows = [];
      filteredData.forEach((row, index) => {
        // Check column values
        for (let colIndex = 0; colIndex < columnCount; colIndex++) {
          let isColumnEmpty =
            row[colIndex] === undefined || row[colIndex].trim() === "";
          if (isColumnEmpty) {
            if (emptyRows.indexOf(index + 1) === -1) {
              emptyRows.push(index + 1);
            }
          }
        }
      });

      if (emptyRows.length > 0) {
        let rowString =
          emptyRows.length <= 10
            ? emptyRows.toString()
            : `More than 10 rows starting at ${emptyRows[0]}`;
        throw new Error(
          `CSV contains empty data columns (row(s): ${rowString})`,
        );
      }

      // Convert the filtered data arrays back into a CSV string
      var csvString = filteredData.map((row) => row.join(",")).join("\n");

      return csvString;
    },
    fileTypeValidation() {
      if (this.importFile === null) {
        this.fileError = "Please Select a File";
        return false;
      }
      switch (this.importFile.type) {
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        case "text/csv":
        case "application/vnd.ms-excel":
          return true;
      }
      this.fileError = "Wrong File type(s). CSV, XLS and XLSX only please";
      return false;
    },
    inputFileFieldOnChange() {
      // check we have the correct file type
      this.fileError = "";
      if (this.importFile !== null) {
        this.fileTypeValidation();
      }
    },
    dateCoverageTextOptions(field) {
      this.fieldSchema.date_coverage_number.label = this.selectedMetaDataObject
        .date_coverage_units.value
        ? "Date coverage number of " +
          this.selectedMetaDataObject.date_coverage_units.value +
          "s"
        : "Date coverage number";

      if (
        (field === "date" ||
          field === "date_coverage_units" ||
          field === "abort" ||
          field === "date_coverage_number") &&
        this.selectedMetaDataObject.date_coverage_number.value &&
        this.selectedMetaDataObject.date_coverage_units.value &&
        this.selectedMetaDataObject.date.value &&
        !this.busy
      ) {
        // call this
        this.busy = true;
        this.fieldSchema.date_coverage_text.loading = true;
        if (field !== "abort") {
          this.selectedMetaDataObject.date_coverage_text.value = null;
        }
        this.fieldSchema.date_coverage_text.disabled = true;
        this.$axios
          .get(
            "/date-coverage-options/" +
              this.selectedMetaDataObject.date_coverage_number.value +
              "/" +
              this.selectedMetaDataObject.date_coverage_units.value +
              "/" +
              this.selectedMetaDataObject.date.value,
          )
          .then(
            function (response) {
              // handle success
              this.fieldSchema.date_coverage_text.selectItems = response.data;
              this.fieldSchema.date_coverage_text.loading = false;
              this.fieldSchema.date_coverage_text.disabled = false;
              setTimeout(() => {
                this.busy = false;
              }, 500);
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.busy = false;
              this.fieldSchema.date_coverage_text.loading = false;
              this.emit.emit("systemMessage", {
                title: "Error! Failed to generate date coverage text",
                message: error.response.data.message,
                timeout: -1,
                colour: "error",
              });
            }.bind(this),
          );
      }
    },
    apiValidationErrors(errors) {
      for (const field in errors) {
        this.fieldSchema[field].apiResponseError = errors[field];
      }
    },
    resetApiValidationErrors() {
      for (var field in this.fieldSchema) {
        this.fieldSchema[field].apiResponseError = null;
      }
    },
    deleteData() {
      this.deleting = true;
      this.$axios
        .post("/custom-data-meta", {
          id: this.selectedMetaDataObject.id.value,
          delete: true,
        })
        .then(() => {
          this.getAllMetadata();
          this.selectedMetaDataItem = null;
          this.confirmDeleteDialog = false;
          this.deleting = false;
          this.emit.emit("systemMessage", {
            title: "Deletion Complete",
            message: "Custom Data Deleted",
            timeout: 3000,
            colour: "success",
          });
        })
        .catch((error) => {
          this.busysaving = false;
          this.emit.emit("systemMessage", {
            title: "Error! Failed to Delete Custom Data",
            message: error.response.data.message,
            timeout: -1,
            colour: "error",
          });
          this.apiValidationErrors(error.response.data.errors);
        });
    },
    fetchAggLevels() {
      this.loadingAggLevels = true;
      this.$axios
        .get("/list-area-data-levels")
        .then((response) => {
          // Begin with the levels we want to use on the map
          let levels = [1, 2, 5, 6];
          // Convert the selected import level value (e.g. msoa) to level (e.g. 2)
          let selectedLevel = this.selectedMetaDataObject.import_level.value;
          if (selectedLevel) {
            let areaLevelNames =
              this.$store.state.config.siteConfig.area_level_names;
            let match = areaLevelNames.find(
              (area) =>
                area.area_level_name_abr.toUpperCase() ===
                selectedLevel.toUpperCase(),
            );
            if (match) {
              let areaLevel = match.area_level;
              // Remove any levels lower than the selected level
              levels = levels.filter((level) => level >= areaLevel);
            }
          }
          // Finally use this filtered down levels array to filter the response data
          this.aggLevels = response.data.filter((level) =>
            levels.includes(level.area_level),
          );
          this.loadingAggLevels = false;
        })
        .catch((error) => {
          console.error("Error fetching aggregation levels:", error);
        });
    },
    save() {
      this.resetApiValidationErrors();
      this.fileError = null;

      if (
        this.selectedMetaDataObject.id.value === null ||
        this.overWriteData ||
        !this.dataCount
      ) {
        if (!this.fileTypeValidation()) {
          if (
            !this.dataCount &&
            this.selectedMetaDataObject.id.value !== null
          ) {
            this.noDataWarning();
          }
          return;
        }
      }
      if (this.selectedMetaDataObject.date.value === "") {
        this.fieldSchema.date.apiResponseError = "Invalid Date";
        return;
      }
      this.$refs.form.validate().then(({ valid: isValid }) => {
        if (isValid) {
          this.fieldSchema.aggregation_type.disabled = true;
          this.fieldSchema.import_level.disabled = true;
          this.saveMetaDataObject = {};
          // Add selected aggregation levels to the save object
          this.selectedMetaDataObject["restrict_agg_on_map"].value =
            this.restrictAggOnMap;
          this.selectedMetaDataObject["map_aggregation_levels"].value =
            JSON.stringify(this.selectedAggLevels);
          // package up the metadata
          this.saveMetaDataObject.overWriteData = this.overWriteData;
          this.saveMetaDataObject.addMoreData = this.addMoreData;
          for (const key in this.selectedMetaDataObject) {
            this.selectedMetaDataObject[key].apiResponseError = null;
            this.saveMetaDataObject[key] =
              this.selectedMetaDataObject[key].value;
          }
          this.busysaving = true;
          this.$axios
            .post("/custom-data-meta", this.saveMetaDataObject)
            .then((response) => {
              // do we need to iport data?
              if (this.importFile !== null) {
                this.importNewData(response);
              } else {
                this.emit.emit("systemMessage", {
                  title: "Success!",
                  message: "Update Complete",
                  timeout: 3000,
                  colour: "success",
                });
                this.afterSaveCleanup(response);
              }
            })
            .catch((error) => {
              this.busysaving = false;
              this.emit.emit("systemMessage", {
                title: "Error! Custom Data Save Failed",
                message: error.response.data.message,
                timeout: -1,
                colour: "error",
              });
              this.apiValidationErrors(error.response.data.errors);
            });
        }
      });
    },
    afterSaveCleanup(response) {
      clearTimeout(this.timer);
      this.addMoreData = false;
      this.addToCurrentDataSelectDate = false;
      this.importingDataDialog = false;
      this.getAllMetadata();
      this.importFile = null;
      this.overWriteData = false;
      for (const field in this.selectedMetaDataObject) {
        if (typeof response.data[field] !== "undefined") {
          this.selectedMetaDataObject[field].value = response.data[field];
        }
      }
      var dateCoverageText = this.$cloneDeep(
        this.selectedMetaDataObject.date_coverage_text.value,
      );
      this.dateCoverageTextOptions("date");
      this.selectedMetaDataObject.date_coverage_text.value = dateCoverageText;
      this.originalDate = this.$cloneDeep(
        this.selectedMetaDataObject.date.value,
      );
      this.selectedMetaDataItem = this.selectedMetaDataObject.id.value;
      // this.dataCount = response.data.dataCount;
      this.$nextTick(() => {
        this.busysaving = false;
      });
    },
    frontendValidation(reset = false) {
      if (reset) {
        this.$refs.form.reset();
      }
    },
    fileBlur() {
      if (!this.importFile && !this.importOptionsDialog) {
        this.abort(true);
      }
    },
    abort(resetDate = false) {
      if (!this.selectedMetaDataItem) {
        return;
      }
      this.addMoreData = false;
      this.overWriteData = false;
      this.confirmOverwrite = false;
      this.importOptionsMessage = false;
      this.addToCurrentDataSelectDate = false;
      this.confirmDeleteDialog = false;
      this.importOptionsDialog = false;
      if (resetDate) {
        this.selectedMetaDataObject.date.value = this.originalDate;
        this.selectedMetaDataObject.date_coverage_text.value =
          this.originalDateCoverageText;
        this.fieldSchema.date.apiResponseError = this.differentDateError;
        this.dateCoverageTextOptions("abort");
      }
    },
    addMoreDataNext() {
      this.overWriteData = false;
      this.addMoreData = true;
      document.getElementById("fileImport").click();
      setTimeout(() => {
        this.importOptionsDialog = false;
        this.dateCoverageTextOptions("date");
      }, 500);
    },
    addMoreDataButton(state) {
      this.confirmOverwrite = false;
      this.addToCurrentDataSelectDate = state;
      this.importOptionsMessage = !state;
      if (state) {
        this.fieldSchema.date.apiResponseError = this.differentDateError;
      } else {
        this.selectedMetaDataObject.date.value = this.originalDate;
        this.fieldSchema.date.apiResponseError = null;
      }
    },
    overWriteButton(state) {
      this.addToCurrentDataSelectDate = false;
      this.confirmOverwrite = state;
      this.importOptionsMessage = !state;
    },
    overWriteDataConfirmed() {
      this.addMoreData = false;
      this.overWriteData = true;
      document.getElementById("fileImport").click();
      setTimeout(() => {
        this.importOptionsDialog = false;
      }, 500);
    },
    fileInputFocus(event) {
      if (!this.selectedMetaDataItem) {
        return;
      }
      if (this.importOptionsDialog) {
        return;
      }
      this.abort();
      this.importOptionsMessage = true;
      this.importOptionsDialog = true;
      event.preventDefault();
    },
    noDataWarning() {
      this.emit.emit("systemMessage", {
        title: "Warning! There is no data associated to this indicator.",
        message:
          "You have no data imported for this indicator. Please select a data file and import your data.",
        timeout: -1,
        colour: "warning",
      });
    },
    getMetaData() {
      this.loadingMetaData = true;
      this.$axios
        .get("/custom-data-meta/" + this.selectedMetaDataItem)
        .then(
          function (response) {
            // data count
            this.dataCount = response.data.dataCount;
            if (!this.dataCount) {
              this.noDataWarning();
            }
            // handle success
            this.selectedMetaDataObject = this.$cloneDeep(this.fieldSchema);
            for (const field in this.selectedMetaDataObject) {
              if (typeof response.data[field] !== "undefined") {
                this.selectedMetaDataObject[field].value = response.data[field];
              }
            }
            // Get map aggregation levels
            this.restrictAggOnMap = response.data.restrict_agg_on_map;
            if (response.data.map_aggregation_levels) {
              this.selectedAggLevels = JSON.parse(
                response.data.map_aggregation_levels,
              );
            }
            var dateCoverageText = this.$cloneDeep(
              this.selectedMetaDataObject.date_coverage_text.value,
            );
            this.dateCoverageTextOptions("date");
            this.selectedMetaDataObject.date_coverage_text.value =
              dateCoverageText;
            this.originalDate = this.$cloneDeep(
              this.selectedMetaDataObject.date.value,
            );
            this.originalDateCoverageText = this.$cloneDeep(
              this.selectedMetaDataObject.date_coverage_text.value,
            );
            this.loadingMetaData = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.loadingMetaData = false;
            this.emit.emit("systemMessage", {
              title: "Error! Failed to load selected custom data",
              message: error.response.data.message,
              timeout: -1,
              colour: "error",
            });
          }.bind(this),
        );
    },
    getAllMetadata() {
      this.busyMetaData = true;
      this.$axios
        .get("/custom-data-meta-all")
        .then(
          function (response) {
            // handle success
            this.allMetaData = response.data;
            this.allMetaData.unshift({ title: "Import New Data", value: 0 });
            this.busyMetaData = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.busyMetaData = false;
            this.emit.emit("systemMessage", {
              title: "Error! Failed to load custom data list",
              message: error.response.data.message,
              timeout: -1,
              colour: "error",
            });
          }.bind(this),
        );
    },
  },
  watch: {
    // watch for changes to the import level and restrict aggregation levels accordingly
    "selectedMetaDataObject.import_level.value": function (newVal) {
      if (newVal) {
        this.fetchAggLevels();
      }
    },
    restrictAggOnMap(newVal) {
      if (newVal) {
        this.fetchAggLevels();
      }
    },
    importingDataDialog: {
      handler(val) {
        if (!val) {
          clearTimeout(this.timer);
        }
      },
    },
    selectedMetaDataObject: {
      handler(val) {
        if (this.loadingMetaData) {
          return;
        }
        if (val) {
          this.fieldSchema.date.apiResponseError = this.differentDateError;
          this.fieldSchema.date_coverage_text.disabled =
            this.disableDateCoverageText;
          this.selectedMetaDataObject.date_coverage_text.value = this
            .disableDateCoverageText
            ? null
            : this.selectedMetaDataObject.date_coverage_text.value;
        }
      },
      deep: true,
    },
    selectedMetaDataItem: {
      handler(val) {
        if (val === 0) {
          // Clear checkboxes when creating new custom dataset
          this.restrictAggOnMap = false;
          this.selectedAggLevels = [];
        }
        this.importFile = null;
        this.fileError = null;
        if (this.busysaving) {
          return;
        } else if (val === null) {
          this.selectedMetaDataObject = null;
        } else if (val === 0) {
          this.selectedMetaDataObject = this.$cloneDeep(this.fieldSchema);
          this.originalDate = "";
          this.fieldSchema.aggregation_type.disabled = false;
          this.fieldSchema.import_level.disabled = false;
          this.$nextTick(() => {
            if (this.$refs.form) {
              this.$refs.form.resetValidation();
            }
          });
        } else {
          this.fieldSchema.aggregation_type.disabled = true;
          this.fieldSchema.import_level.disabled = true;
          this.getMetaData();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.left-align-label {
  text-align: left;
}
.agglevels {
  margin: 0;
  padding: 0;
}
.left-align-label .subheader {
  font-size: 0.8em;
  color: gray;
}
.spinner-container {
  height: 200px;
  position: relative;
  border: 3px solid green;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>
